.header {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 120px;
  }

  &__logo-box {
    @media screen and (max-width: 800px) {
      margin: 0 auto;
    }
  }

  &__logo {
    height: 100%;
    width: 142px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 10px;

    @media screen and (max-width: 800px) {
      margin: unset;
    }
  }

  &__options-primary,
  &__options-secondary {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__options {
    width: 88%;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 25px;
      height: 200px;
      justify-content: center;
    }
  }

  &__option {
    padding: 10px 12px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    cursor: pointer;
    color: #e5e5e5;
    text-decoration: none;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: 800px) {
      padding: 5px 6px;
      font-size: 13px;
    }

    &:hover {
      color: #a9a9a9;
      transition: 0.4s all;
    }

    &--signin {
      margin-right: 25px;
      @media screen and (max-width: 800px) {
        margin: unset;
      }
    }

    &--signout {
      display: inline;
      margin-right: 15px;
      @media screen and (max-width: 800px) {
        margin: unset;
      }
    }
  }

  &__searchbar {
    margin-left: auto;
    margin-right: 15px;

    @media screen and (max-width: 800px) {
      display: none;
      margin: unset;
      position: absolute;
      top: 65%;
      margin: 0 auto;
    }
  }

  &__nav-menu-icon {
    @media screen and (min-width: 800px) {
      display: none;
    }
    @media screen and (max-width: 800px) {
      display: inline-block;
      font-size: 25px;
      color: white;
      position: absolute;
      top: 5%;
      left: 2%;
      padding: 10px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:active {
        transform: scale(1.1);
      }
    }
  }
}
