body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: helvetica;
  background-color: black;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
}

/*#141414*/
