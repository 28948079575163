.container-2 {
  width: 300px;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  margin-top: 2px;
}

.container-2 input#search {
  width: 40px;
  height: 40px;
  background: #141414;
  border: none;
  font-size: 14px;
  float: left;
  color: #262626;
  padding-left: 35px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  outline: none;
  width: 300px;
  background: black;

  -webkit-transition: width 0.55s ease;
  -moz-transition: width 0.55s ease;
  -ms-transition: width 0.55s ease;
  -o-transition: width 0.55s ease;
  transition: width 0.55s ease;
}

.container-2 input#search::-webkit-input-placeholder {
  color: #65737e;
}

.container-2 input#search:-moz-placeholder {
  /* Firefox 18- */
  color: #65737e;
}

.container-2 input#search::-moz-placeholder {
  /* Firefox 19+ */
  color: #65737e;
}

.container-2 input#search:-ms-input-placeholder {
  color: #65737e;
}

.container-2 .search-icon {
  position: absolute;
  top: 50%;
  left: 0%;
  margin-left: 11px;
  margin-top: 11px;
  z-index: 1;
  color: #e5e5e5;
}

.container-2 input#search:focus,
.container-2 input#search:active {
  color: white;
}

.container-2:hover input#search {
  color: white;
}

.container-2:hover .search-icon {
  color: #93a2ad;
}
